import { useRuntimeConfig } from '#imports'
import * as Sentry from '@sentry/nuxt'

Sentry.init({
  // Basic configuration
  dsn: useRuntimeConfig()?.public?.sentry?.dsn || 'https://f6d0c2456c8ff5e3fa1950688137556c@o4508089038733312.ingest.de.sentry.io/4508089089851472',
  // Integrations
  integrations: [
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Feedback UI configuration. Used on error pages and in feedback button in footer.
      autoInject: false, // Disable auto-injection of feedback button. We trigger it manually in the footer.
      colorScheme: 'system',
      showBranding: false,
    }),
    Sentry.browserTracingIntegration(), // Browser tracing integration
    Sentry.piniaIntegration(usePinia()), // Sentry Pinia integration
    // Track Vue components
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true, // Track all components
      },
    }),
  ],
  // Component instrumentation
  trackComponents: true, // Track Vue components
  // Tracing
  // We recommend adjusting this value in production, or using a tracesSampler for finer control.
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://app.soil.link',
    /^\/api\//,
    /^https:\/\/soillink\.app\/api/,
    /^https:\/\/soillink\.dev\/api/,
    // /^https:\/\/soil\.link\/api/,
    /^https:\/\/api\.soil\.link.*/, // Updated to match any path under api.soil.link
    /^https:\/\/app\.soil\.link.*/, // Updated to match any path under api.soil.link
  ],
  // Session Replay
  replaysSessionSampleRate: useRuntimeConfig()?.public?.sentry?.replaysSessionSampleRate || 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: useRuntimeConfig()?.public?.sentry?.replaysOnErrorSampleRate || 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Environment metadata
  environment: useRuntimeConfig()?.public?.environment || undefined,
  release: useRuntimeConfig()?.public?.release || undefined,
})
